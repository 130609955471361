import { EditRecordBase } from "../Lib/EditRecordBase";

export class StoreForm extends EditRecordBase {
    constructor() {
        super('EditRecord', '/BTCAPI/Store/PlaceStoreOrder', '/BTCCheckout/', true);

        // Prep setup functions to run in parallel because they make API server calls
        Promise.all([this.DoSignedIn(), this.PopulateProductsTable()])
            .then(() => {
                this.WireupInputEvents();
                this.pPageBusyIndicator?.Decrement();
            })
    }

    private async DoSignedIn(): Promise<void> {
        this.SignedInMemberInfo().then(async (Member) => {
            if (Member.memberId <= 0) // Not signed-in; Only not-logged in users must pass recaptcha test
                await this.SetupRecaptcha('StoreFormRendered', 'StoreFormSubmitted');
        })
    }

    private static prods: ProdInfo[] | null = null; // Remember page prods to help calc page grand total

    private async PopulateProductsTable(): Promise<void> {
        await jQuery.get('/BTCAPI/Products/GetProducts?Active=Yes&ProdType=Misc')
            .then((prods: ProdInfo[]) => {
                StoreForm.prods = prods;
                const tbody = (document.getElementById("idStoreProducts") as HTMLTableElement).getElementsByTagName('tbody')[0];
                let i = 0;
                prods.forEach(prod => {
                    const prodRow = tbody.insertRow(tbody.rows.length);
                    let row = "";
                    row += `<td>${prod.prodName}`;
                    row += `<input type="hidden" name="item[${i}][ProdId]" value="${prod.prodId}" >`;
                    row += `</td>`;
                    row += `<td>${prod.description}</td>`;

                    // Setup qty td
                    row += '<td class="TextAlign_Right">';
                    let qtyInputType = "number"; // Default to numerical input field
                    let qtyDefault = "";
                    if (!prod.price) { // Don't have a set price so no editable qty field
                        row += '&nbsp;';
                        qtyInputType = "hidden";
                        qtyDefault = "1"; // For hidden qty input, make qty 1 to make line total calcs easier
                    }
                    //row += `<input type="${qtyInputType}" id="Qty-${prod.prodId}" name="Qty-${prod.prodId}" min="0" max="99" Data-prodid="${prod.prodId}" value="${qtyDefault}" >`;
                    row += `<input type="${qtyInputType}" id="Qty-${prod.prodId}" name="item[${i}][Qty]" min="0" max="99" Data-prodid="${prod.prodId}" value="${qtyDefault}"  >`;
                    row += "</td>";

                    // Setup price td
                    row += '<td class="TextAlign_Right">';
                    let priceInputType = "number"; // Default to numerical input field
                    let price = "";
                    if (prod.price) { // Have a set price? Then user can't enter price. Store price in hidden field for calc's
                        price = prod.price.toFixed(2);
                        row += `$${price}`;
                        priceInputType = "hidden"; // Hide input field
                    }
                    //row += `<input type="${priceInputType}" id="Price-${prod.prodId}" name="Price-${prod.prodId}" min="1" max="10000" Data-prodid="${prod.prodId}" value="${price}" >`;
                    row += `<input type="${priceInputType}" id="Price-${prod.prodId}" name="item[${i}][Price]" min="1" max="10000" Data-prodid="${prod.prodId}" value="${price}" >`;
                    row += "</td>";

                    row += `<td class="TextAlign_Right"><span id="LineTotal-${prod.prodId}"></span></td>`;

                    prodRow.innerHTML = row;

                    // Wire up Qty and Price change events
                    (document.getElementById(`Qty-${prod.prodId}`) as HTMLInputElement).addEventListener("input", this.SetLineTotal);
                    (document.getElementById(`Price-${prod.prodId}`) as HTMLInputElement).addEventListener("input", this.SetLineTotal);

                    i++;
                }
                );

                // Send number of products on form to server
                (document.getElementById("NumProds") as HTMLInputElement).value = i.toString();

                // Make grand total row
                const prodRow = tbody.insertRow(tbody.rows.length);
                prodRow.innerHTML = '<td colspan="4"><strong>Total</strong></td><td class="TextAlign_Right"><strong><span id="GrandTotal"></span></strong></td>';
            })
    }

    private SetLineTotal(e: Event): void {
        let prodid = (e.target as HTMLInputElement).dataset.prodid;
        let lt = StoreForm.CalcLinePrice(prodid!);
        ((document.getElementById("LineTotal-" + prodid)) as HTMLSpanElement).innerText = "$" + lt.toFixed(2);
        StoreForm.RecalGrandTotal();
    }

    private static CalcLinePrice(prodid: string): number {
        let qtyinput = (document.getElementById("Qty-" + prodid)) as HTMLInputElement;
        let qty = Math.trunc(Number(qtyinput.value)); // Ensure integer

        let priceinput = (document.getElementById("Price-" + prodid)) as HTMLInputElement;
        let price = Math.trunc(Number(priceinput.value) * 100) / 100.0; // Ensure only two decimal places

        return qty * price;
    }

    private static readonly DonationProdId = 10004;

    private static RecalGrandTotal(): void {
        let GrandTotal = 0;
        StoreForm.prods?.forEach(prod => {
            GrandTotal += StoreForm.CalcLinePrice(prod.prodId.toString());
        });
        (document.getElementById("GrandTotal") as HTMLSpanElement).innerText = "$" + GrandTotal.toFixed(2);
    }

}
