import jQuery from "jquery";
import { EditRecordBase } from "../Lib/EditRecordBase";
import { ReportErrorToServer } from "../Lib/ReportErrorToServer";

export class EditProductForm extends EditRecordBase {
    private Prod: ProdInfo | null = null;

    constructor(protected readonly FormId: string) {
        super(FormId, '/BTCAPI/Products', '/admin/Products');
        (document.getElementById('idMembership') as HTMLInputElement).onclick = this.EnableDisableControls;
        (document.getElementById('idNotMembership') as HTMLInputElement).onclick = this.EnableDisableControls;
        this.GetDataFromServer();
    }

    private async GetDataFromServer(): Promise<void> {
        if (this.RecordId > 0) {
            const getURL: string = '/BTCAPI/Products/' + this.RecordId;
            this.Prod = await jQuery.get(getURL, (prod: ProdInfo) => {
                // Set initial values based on server data
                (document.getElementById('ProdName') as HTMLInputElement).value = prod.prodName;
                (document.getElementById('idActive') as HTMLInputElement).checked = prod.active;
                (document.getElementById('ProdDescription') as HTMLInputElement).value = prod.description;
                (document.getElementById('ProdPrice') as HTMLInputElement).value = prod.price !== null ? prod.price.toFixed(2) : "";
                jQuery(`input[name="ProdType"][value="${prod.membershipTimeframe === null ? "Other" : "Membership"}"]`).prop('checked', true);
                if (prod.membershipTimeframe !== null)
                    jQuery(`input[name="MembershipTimeframe"][value="${prod.membershipTimeframe}"]`).prop('checked', true);
                jQuery(`input[name="NewRenew"][value="${prod.isNewMembership ? "New" : "Renew"}"]`).prop('checked', true);

                if (prod.productInUse) {
                    // Don't let user change certain fields after the product has been referenced by an order
                    (document.getElementById("ProdName") as HTMLInputElement).readOnly = true;
                    (document.getElementById("ProdName") as HTMLInputElement).classList.add("readonlyInput");

                    (document.getElementById("idSetProdType") as HTMLDivElement).style.display = "none";

                    (document.getElementById("idMembershipTimeframeAnnual") as HTMLInputElement).disabled = true;
                    (document.getElementById("idMembershipTimeframeMonth") as HTMLInputElement).disabled = true;
                    (document.getElementById("idNewMember") as HTMLInputElement).disabled = true;
                    (document.getElementById("idRenewingMember") as HTMLInputElement).disabled = true;
                }
            })
                .fail(async (jqXHR, textStatus, errorThrown) => {
                    await ReportErrorToServer.DisplayAndProcessAjaxFailure(getURL, "GET", jqXHR, textStatus, errorThrown, null, null)
                })
        }
        this.EnableDisableControls();
    }

    protected override EnableDisableControls(): void {
        super.EnableDisableControls();

        if (this.Prod && this.Prod.productInUse === true) // Have a product whicn has been bought? Then hide delete button.
            (document.getElementById("Delete") as HTMLButtonElement).style.display = "none";

        if ((document.getElementById("idMembership") as HTMLInputElement).checked)
            (document.getElementById("divMembershipFields") as HTMLDivElement).style.display = "block";
        else
            (document.getElementById("divMembershipFields") as HTMLDivElement).style.display = "none";
    }
}
