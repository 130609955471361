import { MyRecaptchaHandler } from "./ReCaptchaHandler";
import { PageBusyIndicator } from "./PageBusyIndicator";
import { ReportErrorToServer } from "./ReportErrorToServer";

export abstract class EditRecordBase {
    protected static pageBusyIndicator = new PageBusyIndicator();
    protected static myRecaptchaHandler: MyRecaptchaHandler | null = null;

    private _RecordId: number = this.GetRecordIdFromQueryString();
    protected get RecordId(): number { return this._RecordId; }

    private _IsDirty: boolean = false;
    protected get IsDirty(): boolean { return this._IsDirty; }

    constructor(protected readonly FormId: string, protected readonly PutPostAPIUrl: string | null = null,
        protected AfterSubmitGotoWebURL: string = '', protected FadeSuccessMsg: boolean = true,
        protected pPageBusyIndicator: PageBusyIndicator | null = null) {

        // If passed a shared PageBusyIndicator, then use it
        if (pPageBusyIndicator !== null)
            EditRecordBase.pageBusyIndicator = pPageBusyIndicator;

        // Submit and delete buttons will not exist if user is not allowed to edit (eg view only or PayPal checkout).
        const SubmitButton = document.getElementById('Submit') as HTMLButtonElement;
        SubmitButton?.addEventListener('click', async () => await this.DoSubmit());
        if (SubmitButton !== null)
            SubmitButton.disabled = true;

        (document.getElementById('Delete') as HTMLButtonElement)?.addEventListener('click', () => this.DoDelete());

        this.WireupInputEvents();
        this.EnableDisableControls();
        EditRecordBase.pageBusyIndicator.Decrement();
    }

    // Call this from derived class if it has created more elements from code
    protected WireupInputEvents(): void {
        // Call EnableDisable() when select, textarea, or input elements are changed.
        let elems = jQuery('input, select, textarea, .ContentEditable');
        jQuery.each(elems, (_, elem) =>
            elem.addEventListener('input', () => {
                this._IsDirty = true;
                this.EnableDisableControls();
            }));
    }

    protected SignedInMemberInfo = async () => await jQuery.get('/BTCAPI/Members/GetMyAcct?From=EditRecordBase') as MemberInfo;

    // This is called from derived class, if recaptcha is needed
    protected async SetupRecaptcha(recaptchaV3FormName: string, recaptchaV3SubmittedAction: string): Promise<void> {
        EditRecordBase.pageBusyIndicator.Increment(); // Recaptcha setup can take a while

        EditRecordBase.myRecaptchaHandler = new MyRecaptchaHandler(recaptchaV3FormName, recaptchaV3SubmittedAction);
        await EditRecordBase.myRecaptchaHandler.promUserAccepted();
        await EditRecordBase.myRecaptchaHandler.promRecaptchaSetupDone();
        this.EnableDisableControls();

        EditRecordBase.pageBusyIndicator.Decrement();
    }

    private GetRecordIdFromQueryString(): number {
        const qs = window.location.search.substring(1); // Remove leading "?", if any
        const searchParams = new URLSearchParams(qs);
        if (searchParams.has("RecordId"))  // existing record?
            return parseInt(searchParams.get("RecordId")!);
        return -1; // -1 means no record
    }

    protected EnableDisableControls() {
        const bRecaptchaTestRequiredAndFailed = EditRecordBase.myRecaptchaHandler !== null && !EditRecordBase.myRecaptchaHandler.GetUserPassedTest();
        const bDisableAllSubmitButtons = bRecaptchaTestRequiredAndFailed || EditRecordBase.pageBusyIndicator.IsBusy();
        if (document.getElementById('Submit') !== null)
            (document.getElementById('Submit') as HTMLButtonElement).disabled = bDisableAllSubmitButtons || !this.IsDirty;

        if (document.getElementById('Delete') !== null)
            // If no RecordId, then adding new record; nothing to delete
            (document.getElementById('Delete') as HTMLButtonElement).disabled = bDisableAllSubmitButtons || (this.RecordId < 0);
    }

    // If have record ID, then assume PUT (ie update). Otherwise, assume POST (ie new record)
    // This can be overridden by derived class (eg EditMyAcctForm.ts to disallow POST option)
    protected CalcHttpVerb = (): string => (this.RecordId > 0 ? "PUT" : "POST");

    protected async DoSubmit() {
        await this.PagePrepForAjax();

        // Note: Use FormData instead of jQuery("form").serializeArray() because FormData includes empty input fields (https://stackoverflow.com/a/48167577)
        const MyForm: FormData = new FormData(document.getElementById(this.FormId) as HTMLFormElement);
        if (this.PutPostAPIUrl === null)
            throw new Error("URL can't be null");
        let url = this.PutPostAPIUrl;
        if (this.RecordId > 0)  // Have record ID?
            url += "/" + this.RecordId; // Append record ID to url
        const HttpVerb = this.CalcHttpVerb();

        await jQuery.ajax({
            type: HttpVerb,
            data: MyForm,
            url: url,
            contentType: false,
            processData: false // Block jQuery from attempting to parse the FormData assigned to the "data" parameter
        })
            .done((data, textStatus, jqXHR) => {
                if (HttpVerb === "POST")  // Creating new record?
                    this._RecordId = data as number; // data object has new record id returned from server
                this._IsDirty = false;
                jQuery('#SuccessMessage').show();
                if (this.FadeSuccessMsg)
                    jQuery('#SuccessMessage').fadeOut(3000);
            })
            .catch(async (jqXHR, textStatus, errorThrown) => { // Unlike .fail(), .catch() will stop the exception from bubbling up
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(url, HttpVerb, jqXHR, textStatus, errorThrown, MyForm, null);
            })
            .always((data_jqXHR, textStatus, jqXHR_errorThrown) => {
                EditRecordBase.pageBusyIndicator.Decrement();
                this.EnableDisableControls();
                jQuery('form').prop("disabled", false).fadeTo(0, 1); // Re-enable after processing Ajax
                if (!this.IsDirty && this.AfterSubmitGotoWebURL !== "") // Record saved and got URL from jqXHR?
                    window.location.href = this.AfterSubmitGotoWebURL; // Eg: redirect to new record page or checkout page
            });
    }

    private async DoDelete() {
        if (!window.confirm("Are you sure you want to permanently delete this record?"))
            return;

        await this.PagePrepForAjax();
        if (this.PutPostAPIUrl === null)
            throw new Error("URL can't be null");
        const url = this.PutPostAPIUrl + "/" + this.RecordId;

        await jQuery.ajax({ type: "DELETE", url: url })
            .done((_, __, ___) => { window.location.href = this.AfterSubmitGotoWebURL; })
            .catch(async (jqXHR, textStatus, errorThrown) => { // Unlike .fail(), .catch() will stop the exception from bubbling up
                await ReportErrorToServer.DisplayAndProcessAjaxFailure(url, "DELETE", jqXHR, textStatus, errorThrown, null, null)
            })
            .always(() => {
                EditRecordBase.pageBusyIndicator.Decrement();
                this.EnableDisableControls();
                jQuery('form').prop("disabled", false).fadeTo(0, 1); // Re-enable after processing Ajax
            });
    }

    private async PagePrepForAjax(): Promise<void> {
        EditRecordBase.pageBusyIndicator.Increment();

        // Clear any prior messages
        jQuery('#SuccessMessage').hide();
        jQuery('#ErrorMessage').text('');
        jQuery('form').prop("disabled", true).fadeTo(0, 0.5); // Disable while processing Ajax
        this.EnableDisableControls(); // Disable submit button while form is being processed by Ajax.

        if (EditRecordBase.myRecaptchaHandler !== null)
            await EditRecordBase.myRecaptchaHandler.GetNewOneTimeV3TokenIfNeeded();
    }
}
